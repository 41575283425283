import * as THREE from "../../libs/three.js/build/three.module.js";
import { BoxVolume } from "./Volume.js";
import { Utils } from "../utils.js";
import { PointSizeType } from "../defines.js";
import { Profile } from "./Profile.js";
import { EventDispatcher } from "../EventDispatcher.js";

export class ScreenBoxProfileSelectTool extends EventDispatcher {
  constructor(viewer) {
    super();

    this.viewer = viewer;
    this.renderer = viewer.renderer;
    this.scene = new THREE.Scene();

    viewer.addEventListener("update", this.update.bind(this));
    viewer.addEventListener(
      "render.pass.perspective_overlay",
      this.render.bind(this)
    );
    viewer.addEventListener("scene_changed", this.onSceneChange.bind(this));
  }

  onSceneChange(scene) {
    console.log("scene changed");
  }

  startInsertion() {
    let domElement = this.viewer.renderer.domElement;

    let profile = new Profile();
    profile.name = "Profile Rectangle";

    this.viewer.scene.addProfile(profile);

    this.importance = 10;

    this.firstPoint = null;
    this.endPoint = null;

    let posDragEnd;

    let selectionBox = $(
      `<div style="position: absolute; border: 2px solid white; pointer-events: none; box-sizing: border-box;"></div>`
    );
    $(domElement.parentElement).append(selectionBox);
    selectionBox.css("right", "10px");
    selectionBox.css("bottom", "10px");

    let processDrag = (dragEnd) => {
      let rect = domElement.getBoundingClientRect();

      let mEnd = dragEnd;
      let width = 0.5;

      posDragEnd = dragEnd;

      if (this.firstPoint != null && this.endPoint != null) {
        mEnd = this.endPoint;
      } else if (this.firstPoint != null) {
        mEnd = dragEnd;
      } else {
        return;
      }

      if (this.firstPoint.x == mEnd.x && this.firstPoint.y == mEnd.y) {
        return;
      }

      if (profile.points.length > 0) {
        let screenPos = profile.points[0]
          .clone()
          .project(this.viewer.scene.getActiveCamera());
        this.firstPoint.x = ((screenPos.x + 1) / 2) * rect.width;
        this.firstPoint.y = (-(screenPos.y - 1) / 2) * rect.height;
      }

      if (profile.points.length > 1) {
        let screenPos = profile.points[1]
          .clone()
          .project(this.viewer.scene.getActiveCamera());
        this.endPoint.x = ((screenPos.x + 1) / 2) * rect.width;
        this.endPoint.y = (-(screenPos.y - 1) / 2) * rect.height;
      }

      let screenCentroid = new THREE.Vector2()
        .addVectors(this.firstPoint, mEnd)
        .multiplyScalar(0.5);
      let height = this.firstPoint.distanceTo(mEnd);
      let endDistance = this.firstPoint.distanceTo(dragEnd);

      if (endDistance != 0 && height != 0) {
        let rotateEnd = Math.min(
          1,
          Math.abs(
            ((mEnd.x - this.firstPoint.x) * (dragEnd.x - this.firstPoint.x) +
              (mEnd.y - this.firstPoint.y) * (dragEnd.y - this.firstPoint.y)) /
              height /
              endDistance
          )
        );

        width = Math.abs(endDistance * Math.sqrt(1 - rotateEnd * rotateEnd));
      }

      if (width < 0.5) width = 0.5;

      let box2D = new THREE.Box2();
      box2D.expandByPoint(
        new THREE.Vector2(
          screenCentroid.x - width,
          screenCentroid.y - height / 2
        )
      );
      box2D.expandByPoint(
        new THREE.Vector2(
          screenCentroid.x + width,
          screenCentroid.y + height / 2
        )
      );

      selectionBox.css("left", `${box2D.min.x}px`);
      selectionBox.css("top", `${box2D.min.y}px`);
      selectionBox.css("width", `${width * 2}px`);
      selectionBox.css("height", `${height}px`);

      let rotateVector = new THREE.Vector2(
        this.firstPoint.x - screenCentroid.x,
        this.firstPoint.y - screenCentroid.y
      );
      let distance = screenCentroid.distanceTo(this.firstPoint);
      let rotate = Math.acos((screenCentroid.y - this.firstPoint.y) / distance);
      if (this.firstPoint.x < screenCentroid.x) {
        rotate = Math.PI * 2 - rotate;
      }
      selectionBox.css("transform", `rotate(${(rotate / Math.PI) * 180}deg)`);

      let radiusRect = box2D.min.distanceTo(screenCentroid);
      let angleRect = Math.atan((width / height) * 2);
      let fourCorners = [];
      fourCorners.push(
        new THREE.Vector2(
          screenCentroid.x + Math.sin(rotate - angleRect) * radiusRect,
          screenCentroid.y - Math.cos(rotate - angleRect) * radiusRect
        )
      );
      fourCorners.push(
        new THREE.Vector2(
          screenCentroid.x + Math.sin(rotate + angleRect) * radiusRect,
          screenCentroid.y - Math.cos(rotate + angleRect) * radiusRect
        )
      );

      let pointclouds = [];

      for (let i = 0; i < this.viewer.scene.pointclouds.length; i++) {
        if (this.viewer.scene.pointclouds[i].pcoGeometry.isPotreeType == 0) {
          pointclouds.push(this.viewer.scene.pointclouds[i]);
        }
      }

      for (let i = 0; i < fourCorners.length; i++) {
        let I = Utils.getMousePointCloudIntersectionLocation(
          fourCorners[i],
          this.viewer.scene.getActiveCamera(),
          this.viewer,
          pointclouds,
          { pickClipped: true }
        );

        if (I) {
          profile.setWidth(I.location.distanceTo(profile.points[0]) * 2);
          break;
        }
      }
    };

    let drag = (e) => {
      let rect = domElement.getBoundingClientRect();
      let x = e.clientX - rect.left;
      let y = e.clientY - rect.top;
      let dragEnd = new THREE.Vector2(x, y);

      processDrag(dragEnd);
    };

    let drop = (e) => {
      let rect = domElement.getBoundingClientRect();
      let x = e.clientX - rect.left;
      let y = e.clientY - rect.top;
      let dragEnd = new THREE.Vector2(x, y);
      let pointclouds = [];

      for (let i = 0; i < this.viewer.scene.pointclouds.length; i++) {
        if (this.viewer.scene.pointclouds[i].pcoGeometry.isPotreeType == 0) {
          pointclouds.push(this.viewer.scene.pointclouds[i]);
        }
      }

      if (this.firstPoint == null) {
        this.firstPoint = dragEnd;

        profile.addMarker(new THREE.Vector3(0, 0, 0));

        let I = Utils.getMousePointCloudIntersectionLocation(
          this.firstPoint,
          this.viewer.scene.getActiveCamera(),
          this.viewer,
          pointclouds,
          { pickClipped: true }
        );

        if (I) {
          profile.setPosition(0, I.location);
        }
      } else if (this.endPoint == null) {
        this.endPoint = dragEnd;

        if (
          this.endPoint.x == this.firstPoint.x &&
          this.endPoint.y == this.firstPoint.y
        ) {
          this.endPoint.y++;
        }

        profile.addMarker(new THREE.Vector3(0, 0, 0));

        let I = Utils.getMousePointCloudIntersectionLocation(
          this.endPoint,
          this.viewer.scene.getActiveCamera(),
          this.viewer,
          pointclouds,
          { pickClipped: true }
        );

        if (I) {
          profile.setPosition(1, I.location);
        }
      } else {
        this.importance = 0;
        $(selectionBox).remove();

        domElement.removeEventListener("mousemove", drag);
        domElement.removeEventListener("mousedown", drop);
        domElement.removeEventListener("mousewheel", wheel);
        domElement.removeEventListener("DOMMouseScroll", wheel);
      }
    };

    let removed = (e) => {
      this.importance = 0;
      $(selectionBox).remove();

      domElement.removeEventListener("mousemove", drag);
      domElement.removeEventListener("mousedown", drop);
      domElement.removeEventListener("mousewheel", wheel);
      domElement.removeEventListener("DOMMouseScroll", wheel);
      viewer.scene.removeEventListener("profile_removed", removed);
      domElement.removeEventListener("keydown", keyEventCallback);
    };

    let wheelTimeout;

    let wheel = (e) => {
      if (wheelTimeout) {
        clearTimeout(wheelTimeout);
        wheelTimeout = null;
      }

      wheelTimeout = setTimeout(function () {
        processDrag(posDragEnd);
      }, 200);
    };

    let keyEventCallback = (e) => {
      if (e.key === "Escape" || e.key === "Esc") {
        removed();
      }
    };

    domElement.addEventListener("keydown", keyEventCallback);

    viewer.scene.addEventListener("profile_removed", removed);

    domElement.addEventListener("mousemove", drag);
    domElement.addEventListener("mousedown", drop);
    domElement.addEventListener("mousewheel", wheel);
    domElement.addEventListener("DOMMouseScroll", wheel); // Firefox

    return profile;
  }

  update(e) {
    //console.log(e.delta)
    let camera = this.viewer.scene.getActiveCamera();
    let profiles = this.viewer.scene.profiles;
    let clientWidth = this.renderer.getSize(new THREE.Vector2()).width;
    let clientHeight = this.renderer.getSize(new THREE.Vector2()).height;

    // make size independant of distance
    for (let profile of profiles) {
      for (let sphere of profile.spheres) {
        let distance = camera.position.distanceTo(sphere.getWorldPosition());
        let pr = Utils.projectedRadius(
          1,
          camera,
          distance,
          clientWidth,
          clientHeight
        );
        let scale = 15 / pr;
        sphere.scale.set(scale, scale, scale);
      }
    }
  }

  render() {
    this.viewer.renderer.render(
      this.scene,
      this.viewer.scene.getActiveCamera()
    );
  }
}
